import { computed, createApp } from 'vue';
import { createDesignSystem, components } from '@infusionsoft/design-system';
import { createLibrary } from '@keap-web/content-sharing-lib';
import firebase from 'firebase/compat/app';

import App from '@/App.vue';
import { createRouter } from '@/router';
import { i18n } from '@/i18n/index';
import { setupInterceptors } from '@/utils/interceptor.util';
import { handlePromiseRejections, handleError } from '@/utils/error.util';
import VueClipboard from 'vue-clipboard2';
import '@/styles/main.scss';
import { createPinia } from 'pinia';
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate';
import { useGlobalStore } from '@/stores/global';
import { createApolloClient } from '@keap-web/apollo-lib';
import { createPendo } from '@keap-web/analytics-lib/pendo';
import { createFullStory } from '@keap-web/analytics-lib/fullstory';
import { createSentry } from '@keap-web/analytics-lib/sentry';

import { useAuthStore } from '@/stores/auth';
import { useAccountsStore } from '@/accounts/store';

const app = createApp(App);
const router = createRouter();
const pinia = createPinia();

pinia.use(piniaPluginPersistedstate);

const designSystem = createDesignSystem({
    components,
    useNewIllustrations: true,
    dropdownNext: true,
    guideNext: true,
    popoverNext: true,
    tooltipNext: true,
    focusOnKeyboard: true,
    onOverlayActive: () => { useGlobalStore().activateOverlay(); },
    onOverlayInactive: () => { useGlobalStore().deactivateOverlay(); },
});
const contentSharingLib = createLibrary({
    packagingServiceUrl: window.__KEAP__.VUE_APP_PACKAGING_SERVICE_URL,
    webUrl: window.__KEAP__.VUE_APP_KEAP_URL,
    ultimateUrl: window.__KEAP__.VUE_APP_KEAP_ULTIMATE_URL,
    classicUrl: window.__KEAP__.VUE_APP_CORE_URL_PATTERN,
});

app.config.errorHandler = handleError;
handlePromiseRejections();

const fullstory = createFullStory({
    orgId: window.__KEAP__.VUE_APP_FULLSTORY_ORG,
    devMode: import.meta.env.DEV,
    enabled: import.meta.env.VUE_APP_THIRD_PARTY_LOGGING === 'enabled',
});

const apolloClient = createApolloClient({
    uri: window.__KEAP__.VUE_APP_FLAGSHIP_BFF_URL,
    jwt: computed(() => useAuthStore().jwt),
    appId: computed(() => {
        return window.__KEAP__.VUE_APP_APP_ID ? window.__KEAP__.VUE_APP_APP_ID : useAccountsStore().appId;
    }),
});
const pendo = createPendo({
    apiKey: window.__KEAP__.VUE_APP_PENDO_API_KEY,
    enabled: import.meta.env.VUE_APP_THIRD_PARTY_LOGGING === 'enabled',
});

const sentry = createSentry({
    enabled: import.meta.env.VUE_APP_THIRD_PARTY_LOGGING === 'enabled',
    dsn: import.meta.env.VUE_APP_SENTRY_KEY,
    org: import.meta.env.VUE_APP_SENTRY_ORG,
    release: import.meta.env.SENTRY_RELEASE,
    environment: window.__KEAP__.VUE_APP_ENV,
    router,
});

app.use(designSystem);
app.use(router);
app.use(pinia);
app.use(i18n);
app.use(VueClipboard);
app.use(contentSharingLib);
app.use(apolloClient);
app.use(pendo);
app.use(fullstory);
app.use(sentry);

setupInterceptors(router);

const config = {
    apiKey: window.__KEAP__.VUE_APP_FIREBASE_API_KEY,
    projectId: window.__KEAP__.VUE_APP_FIREBASE_PROJECT_ID,
    databaseURL: `https://${window.__KEAP__.VUE_APP_FIREBASE_PROJECT_ID}.firebaseio.com`,
};

firebase.initializeApp(config);

app.mount('#app');
