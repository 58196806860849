import { defineStore } from 'pinia';
import { loadSettings } from './api';
import {
    IDLE, LOADING, SUCCESS, ERROR,
} from '@/constants/loadingStatuses.constants';

// These values are defined in packaging service
const OWNER_TYPES = {
    KEAP: 'Keap',
    INFLUENCER: 'Influencer',
    CERTIFIED_PARTNER: 'CertifiedPartner',
};

export const useTenantSettingsStore = defineStore('tenantSettings', {
    state: () => ({
        loadingStatus: IDLE,
        settings: {},
    }),

    actions: {
        async loadSettings(appId) {
            try {
                this.loadingStatus = LOADING;
                const data = await loadSettings(appId);

                this.settings = data;
                this.loadingStatus = SUCCESS;
            } catch {
                // this will throw a 404 most of the time
                this.loadingStatus = ERROR;
            }
        },

        reset() {
            this.$reset();
        },
    },

    getters: {
        canUseInternalCartId({ settings }) {
            return [OWNER_TYPES.KEAP, OWNER_TYPES.INFLUENCER].includes(settings?.ownerType);
        },
    },
});
